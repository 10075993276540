import _ from 'lodash';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

import { FaUser } from 'react-icons/fa';
import { BiCalendar } from 'react-icons/bi';

import { useDateUpdate } from 'src/utils/helper';
import { NewHighlight } from 'src/modules/sections/section-1';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

const Image = dynamic(import('src/components/image'));

type Props = {
  data: NewHighlight;
  showTitle: boolean;
  showBlurb: boolean;
  showByline: boolean;
  showTime: boolean;
  showCountview: boolean;
  heading: string | any;
  external: boolean;
  eventDataLayer: any;
};

const CardOverlayFull = ({
  data,
  showTitle = true,
  showBlurb = true,
  showByline = true,
  showTime = true,
  // showCountview = true, // countView,
  // heading,
  external,
  eventDataLayer = {}
}: Props) => {
  return (
    <>

      <CardWrapper className='card-full-overlay'>
        {!_.isEmpty(data) && (
          <a
            href={data?.link}
            tabIndex={-1}
            // title={data?.title}
            //! DATALAYER
            onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
          >
            <div className='img-scale-animate'>
              <div className='card-full-thumb'>
                <Image image={data?.image} title={data?.title} external={external} />
              </div>
              <div className='card --content'>
                {showTitle && <h3 className='title text-excerpt-2'>{data?.title}</h3>}
                {showBlurb && <p className='blurb text-excerpt-2'>{data?.blurb}</p>}
                <div className='meta-contents'>
                  {showByline && !_.isEmpty(data?.byline) && (
                    <div className='byline'>
                      <FaUser /> <span>{data?.byline}</span>
                    </div>
                  )}
                  <div className='meta'>
                    {showTime && (
                      <p className='date-time'>
                        <BiCalendar />
                        <span>{useDateUpdate(data?.published_at, false)} </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </a>
        )}
      </CardWrapper>
    </>
  );
};

const CardWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: block;
  .img-scale-animate {
    &:hover {
      .card-full-thumb {
        background: rgb(0, 0, 0, 0);
      }
      h2,
      h3,
      p {
        color: #ed1a3b !important;
      }
    }
    .card-full-thumb {
      background: rgb(0, 0, 0, 0.2);
      height: 550px;
      overflow: hidden;
      @media (max-width: 480px) {
        height: 410px;
      }
      .resolution-image {
        /* height: 65vh; */
        /* padding-top: 130% !important; */
        height: 550px;
        overflow: hidden;
        border-radius: 3px;
        @media (max-width: 480px) {
          height: 410px;
        }
        img {
          z-index: -1;
        }
      }
    }
    .card {
      position: absolute;
      left: 0;
      bottom: 0;
      h2,
      h3,
      p {
        transition: all 0.3s ease-in-out;
        color: white;
      }
      &.--category {
        width: 100%;
        text-align: center;
        bottom: unset;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 10px;
        h2 {
          font-size: 36px;
          font-weight: 400;
          text-transform: uppercase;
        }
      }
      &.--content {
        text-align: center;
        background-color: #000000a7;
        padding: 15px 20px;
        width: 100%;
        max-width: 100%;
        min-height: 72px;
        h3 {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
`;

export default CardOverlayFull;
